import React, { useState } from "react";
import { useWindowWidth } from "../../breakpoints";
import { Logo2 } from "../../icons/Logo2";
import "./style.css";
import Swal from "sweetalert2";

export const Index = () => {
  const screenWidth = useWindowWidth();
  const [isClicked, setIsClicked] = useState(false);
  const [isClicked1, setIsClicked1] = useState(false);
  const [isClicked2, setIsClicked2] = useState(false);
  const [isClicked3, setIsClicked3] = useState(false);
  const [isClicked4, setIsClicked4] = useState(false);
  const [isClicked5, setIsClicked5] = useState(false);
  const [isClicked6, setIsClicked6] = useState(false);

  const [isClicked7, setIsClicked7] = useState(false);
  const [isClicked8, setIsClicked8] = useState(false);
  const [isClicked9, setIsClicked9] = useState(false);
  const [isClicked10, setIsClicked10] = useState(false);
  const [isClicked11, setIsClicked11] = useState(false);

  const handleClick7 = () => {
    setIsClicked7(true);
    setTimeout(() => {
      setIsClicked7(false);
    }, 300); // Adjust the duration to match your animation time
  };
  const handleClick8 = () => {
    setIsClicked8(true);
    setTimeout(() => {
      setIsClicked8(false);
    }, 300); // Adjust the duration to match your animation time
  };
  const handleClick9 = () => {
    setIsClicked9(true);
    setTimeout(() => {
      setIsClicked9(false);
    }, 300); // Adjust the duration to match your animation time
  };
  const handleClick10 = () => {
    setIsClicked10(true);
    setTimeout(() => {
      setIsClicked10(false);
    }, 300); // Adjust the duration to match your animation time
  };
  const handleClick11 = () => {
    setIsClicked11(true);
    setTimeout(() => {
      setIsClicked11(false);
    }, 300); // Adjust the duration to match your animation time
  };
  const handleClick1 = () => {
    setIsClicked1(true);
    setTimeout(() => {
      setIsClicked1(false);
    }, 300); // Adjust the duration to match your animation time
  };

  const handleClick2 = () => {
    setIsClicked2(true);
    setTimeout(() => {
      setIsClicked2(false);
    }, 300); // Adjust the duration to match your animation time
  };

  const handleClick3 = () => {
    setIsClicked3(true);
    setTimeout(() => {
      setIsClicked3(false);
    }, 300); // Adjust the duration to match your animation time
  };

  const handleClick4 = () => {
    setIsClicked4(true);
    setTimeout(() => {
      setIsClicked4(false);
    }, 300); // Adjust the duration to match your animation time
  };
  const handleClick5 = () => {
    setIsClicked5(true);
    setTimeout(() => {
      setIsClicked5(false);
    }, 300); // Adjust the duration to match your animation time
  };

  const handleClick6 = () => {
    setIsClicked6(true);
    setTimeout(() => {
      setIsClicked6(false);
    }, 300); // Adjust the duration to match your animation time
  };

  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [email3, setEmail3] = useState("");
  const [email4, setEmail4] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailChange2 = (event) => {
    setEmail2(event.target.value);
  };

  const handleEmailChange3 = (event) => {
    setEmail3(event.target.value);
  };

  const handleEmailChange4 = (event) => {
    setEmail4(event.target.value);
  };

  const handleSubmit = () => {
    handleClick7();
    // Check if the email is valid before sending it to the server
    if (isValidEmail(email)) {
      sendEmailToServer(email);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid email address. Please enter a valid email.",
      });
    }
  };

  const handleSubmit2 = () => {
    handleClick8();
    // Check if the email is valid before sending it to the server
    if (isValidEmail(email2)) {
      sendEmailToServer(email2);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid email address. Please enter a valid email.",
      });
    }
  };
  const handleSubmit3 = () => {
    handleClick9();
    // Check if the email is valid before sending it to the server
    if (isValidEmail(email3)) {
      sendEmailToServer(email3);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid email address. Please enter a valid email.",
      });
    }
  };

  const handleSubmit4 = () => {
    handleClick10();
    // Check if the email is valid before sending it to the server
    if (isValidEmail(email4)) {
      sendEmailToServer(email4);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid email address. Please enter a valid email.",
      });
    }
  };

  const isValidEmail = (email) => {
    // Implement your own email validation logic here
    // This is a simple example; you may want to use a more robust validation method
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEmailToServer = (email) => {
    // Replace 'your-backend-endpoint' with the actual endpoint on your backend server
    const backendEndpoint =
      "https://htbs-backend-dev.azurewebsites.net/api/emailsubscribers/";

    // Make a POST request to your backend server
    const webData = {
      browser: navigator.userAgent,
      language: navigator.language,
      // Add more properties as needed
    };

    // Combine all data into a single object
    const requestData = {
      email: email,
      data: JSON.stringify(webData),
    };
    Swal.fire({
      title: "Email sent successfully!",
      width: 600,
      padding: "3em",
      color: "#716add",
      background: "#fff url(/images/trees.png)",
      backdrop: `
        rgba(0,0,123,0.4)
        url("/images/nyan-cat.gif")
        left top
        no-repeat
      `,
    });
    // Make a POST request to your backend server
    fetch(backendEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        // Handle the response as needed
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to send email. Please try again later.",
          footer: '<a href="#">Why do I have this issue?</a>',
        });
      });
  };

  const [isClickedSection, setIsClickedSection] = useState(false);

  const handleClickSection = () => {
    setIsClickedSection(true);
    setTimeout(() => {
      setIsClickedSection(false);
    }, 300); // Adjust the duration to match your animation time
  };

  const scrollToNextSectionWhy = () => {
    const nextSection = document.getElementById("why-it-works"); // Replace 'nextSection' with the actual ID of your next section
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToNextSectionShit = () => {
    const nextSection = document.getElementById("stop-believing-in-this-shit"); // Replace 'nextSection' with the actual ID of your next section
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToNextSectionHow = () => {
    const nextSection = document.getElementById("how-it-works"); // Replace 'nextSection' with the actual ID of your next section
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="index">
      <div
        className="HTBS-page"
        style={{
          height:
            screenWidth < 1280
              ? "4211px"
              : screenWidth >= 1280
              ? "3600px"
              : undefined,
          position: screenWidth < 1280 ? "relative" : "relative",
          width:
            screenWidth < 1280
              ? "360px"
              : screenWidth >= 1280
              ? "1440px"
              : undefined,
        }}
      >
        {screenWidth < 1280 && (
          <>
            <div className="overlap">
              <div className="blue-blur" />
              <div className="div" />
              <img
                className="stroke-circle"
                alt="Stroke circle"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/stroke-circle.svg"
              />
              <div className="stroke-circle-2" />
              <div className="txt-container">
                <img
                  className="vector"
                  alt="Vector"
                  src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-19.svg"
                />
                <p className="title">
                  <span className="text-wrapper">Get a free app</span>
                  <div className="tag-100">
                    <div className="text-wrapper-14">BETA</div>
                  </div>
                  <span className="span"> to achieve </span>
                  <span className="text-wrapper">goals</span>
                </p>
                <div className="form-container">
                  <input
                    className="input"
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <button
                    className={`button ${isClicked7 ? "clicked" : ""}`}
                    onClick={handleSubmit}
                  >
                    <div className="text-wrapper-2">Submit</div>
                  </button>
                </div>
              </div>
              <div className="header">
                <Logo2 className="logo" />
                {/* <div className="button-2">
                  <div className="rectangle" />
                  <div className="rectangle" />
                  <div className="rectangle" />
                </div> */}
              </div>
              <img
                className="img"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-17.svg"
              />
              <div className="cards">
                <div className="card">
                  <div className="ant-design-smile">
                    <div className="overlap-group">
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-22.svg"
                      />
                      <img
                        className="vector-3"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-24.svg"
                      />
                      <img
                        className="vector-4"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-25.svg"
                      />
                      <img
                        className="vector-5"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-23.svg"
                      />
                      <img
                        className="vector-6"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector.svg"
                      />
                      <div className="ellipse" />
                      <img
                        className="vector-7"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-21.svg"
                      />
                    </div>
                  </div>
                  <div className="copy-container">
                    <div className="title-2">Self-awareness</div>
                    <p className="p">
                      Realize what was important today and why was it important
                      to you
                    </p>
                  </div>
                  <img
                    className="group"
                    alt="Group"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/group-7@2x.png"
                  />
                </div>
                <div className="copy-container-wrapper">
                  <div className="copy-container-2">
                    <div className="title-3">Instrument</div>
                    <div className="frame">
                      <div className="frame-2">
                        <img
                          className="vector-stroke"
                          alt="Vector stroke"
                          src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-5--stroke-.svg"
                        />
                        <div className="title-4">Books (knowledge)</div>
                      </div>
                      <div className="frame-2">
                        <img
                          className="vector-stroke"
                          alt="Vector stroke"
                          src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-5--stroke--1.svg"
                        />
                        <div className="title-4">Gym (muscles)</div>
                      </div>
                      <div className="frame-2">
                        <img
                          className="vector-stroke"
                          alt="Vector stroke"
                          src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-5--stroke-.svg"
                        />
                        <div className="title-4">Cookery School (food)</div>
                      </div>
                      <div className="frame-2">
                        <img
                          className="vector-stroke"
                          alt="Vector stroke"
                          src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-5--stroke-.svg"
                        />
                        <div className="title-4">Piano lessons (music)</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="group-2"
                  alt="Group"
                  src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/group-6@2x.png"
                />
                <div className="card">
                  <div className="ant-design-smile-2">
                    <div className="overlap-group-2">
                      <img
                        className="vector-8"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-22-1.svg"
                      />
                      <img
                        className="vector-9"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-24-1.svg"
                      />
                      <img
                        className="vector-10"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-25-1.svg"
                      />
                      <img
                        className="vector-11"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-23-1.svg"
                      />
                      <img
                        className="vector-12"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-1.svg"
                      />
                      <div className="ellipse-2" />
                      <img
                        className="vector-13"
                        alt="Vector"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-21-1.svg"
                      />
                    </div>
                    <img
                      className="title-5"
                      alt="Title"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/title.svg"
                    />
                  </div>
                  <div className="copy-container">
                    <div className="title-6">Goals</div>
                    <p className="p">
                      Define where do you
                      <br />
                      want to be successful
                      <br />
                      (what do you want to achieve)
                    </p>
                  </div>
                </div>
                <img
                  className="group-3"
                  alt="Group"
                  src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/group-5@2x.png"
                />
              </div>
            </div>
            <div className="overlap-2">
              <div className="txt-container-2">
                <p className="title-7">
                  <span className="text-wrapper">Why </span>
                  <span className="text-wrapper-3">HTBS?</span>
                  <span className="text-wrapper"> Because</span>
                </p>
                <p className="p">Real success comes from nothing</p>
              </div>
              <img
                className="vector-14"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-18.svg"
              />
            </div>
            <div className="graphics">
              <div className="overlap-3">
                <div className="overlap-4">
                  <div className="blue-blur-2" />
                  <img
                    className="image"
                    alt="Image"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/image-13@2x.png"
                  />
                  <div className="rectangle-2" />
                  <img
                    className="image-2"
                    alt="Image"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/image-14@2x.png"
                  />
                  <img
                    className="image-3"
                    alt="Image"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/image-15@2x.png"
                  />
                  <div className="rectangle-3" />
                  <div className="rectangle-4" />
                  <div className="rectangle-5" />
                  <div className="rectangle-6" />
                  <div className="rectangle-7" />
                  <div className="card-2">
                    <div className="date">
                      <div className="text-wrapper-4">27 December</div>
                    </div>
                    <div className="text-container">
                      <div className="copy-container-3">
                        <div className="content-row">
                          <div className="text-wrapper-5">1.</div>
                          <div className="text-wrapper-6">
                            I took some water
                          </div>
                          <img
                            className="tag"
                            alt="Tag"
                            src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                          />
                        </div>
                        <div className="content-row">
                          <div className="text-wrapper-5">2.</div>
                          <div className="text-wrapper-6">
                            I took something else...
                          </div>
                          <img
                            className="tag-2"
                            alt="Tag"
                            src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                          />
                        </div>
                        <img
                          className="tag-3"
                          alt="Tag"
                          src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                        />
                      </div>
                      <div className="text-wrapper-7">Done</div>
                    </div>
                  </div>
                  <div className="frame-3">
                    <div className="column">
                      <div className="text-wrapper-8">03.01.2024</div>
                      <div className="text-wrapper-9">1 книга на місяць</div>
                      <img
                        className="tag-4"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                    <div className="column-2">
                      <div className="text-wrapper-8">03.01.2024</div>
                      <p className="text-wrapper-9">
                        Вільно навчитись грати блюз на піаніно
                      </p>
                      <img
                        className="tag-5"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                    <div className="column">
                      <div className="text-wrapper-8">03.01.2024</div>
                      <div className="text-wrapper-9">Стенд ап</div>
                      <img
                        className="tag-6"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                    <div className="column">
                      <div className="text-wrapper-8">03.01.2024</div>
                      <div className="text-wrapper-9">Table 9</div>
                      <img
                        className="tag-7"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-container-2">
                  <div className="text-wrapper-10">Profile</div>
                  <div className="copy-container-4">
                    <div className="content-row-2">
                      <img
                        className="solar-book-outline"
                        alt="Solar book outline"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                      <div className="text-wrapper-11">Books</div>
                      <img
                        className="back"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                    <img
                      className="vector-15"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                    />
                    <div className="content-row-2">
                      <img
                        className="fluent-list"
                        alt="Fluent list"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                      <div className="text-wrapper-11">Habits</div>
                      <img
                        className="back-2"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                    <img
                      className="vector-16"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                    />
                    <div className="content-row-2">
                      <img
                        className="basil-calendar"
                        alt="Basil calendar"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                      <div className="text-wrapper-11">Plan</div>
                      <img
                        className="back-3"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                    <img
                      className="vector-17"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                    />
                    <div className="content-row-2">
                      <img
                        className="solar-archive"
                        alt="Solar archive"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                      <div className="text-wrapper-11">Archive</div>
                      <img
                        className="back-4"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                    <img
                      className="vector-18"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                    />
                    <div className="content-row-2">
                      <img
                        className="solar-document"
                        alt="Solar document"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                      <div className="text-wrapper-11">
                        Terms &amp; Policies
                      </div>
                      <img
                        className="back-5"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-13@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="txt-container-3">
              <img
                className="vector-19"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-19-1.svg"
              />
              <p className="title-8">Stop believing in this sh*t 💩</p>
              <div className="cards-2">
                <div className="frame-4">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <div className="title-9">
                        Buy
                        <br />a course
                      </div>
                      <img
                        className="game-icons-female"
                        alt="Game icons female"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/game-icons-female-legs.svg"
                      />
                      <div className="rectangle-8" />
                    </div>
                  </div>
                  <p className="title-10">Do you want to be successful</p>
                </div>
                <div className="frame-4">
                  <div className="div-wrapper">
                    <div className="overlap-group-3">
                      <div className="overlap-5">
                        <div className="overlap-6">
                          <div className="title-11">7</div>
                          <div className="rectangle-9" />
                          <div className="rectangle-10" />
                        </div>
                        <div className="overlap-7">
                          <div className="title-12">7</div>
                          <div className="rectangle-11" />
                        </div>
                      </div>
                      <div className="overlap-8">
                        <div className="title-12">7</div>
                        <div className="rectangle-11" />
                      </div>
                    </div>
                  </div>
                  <div className="title-wrapper">
                    <p className="title-13">
                      You won 100K,
                      <br />
                      take you win!
                    </p>
                  </div>
                </div>
                <div className="frame-4">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <div className="title-14">
                        $1M in
                        <br />a month
                      </div>
                      <div className="rectangle-8" />
                    </div>
                  </div>
                  <p className="title-10">
                    How I get
                    <br />
                    $1M in a month?
                  </p>
                </div>
              </div>
            </div>
            <div className="frame-5">
              <div className="txt-container-4">
                <div className="title-8">Real approach</div>
                <p className="p">
                  One of many, this one has worked
                  <br />
                  for me for the past 3 years.
                </p>
              </div>
              <div className="copy-container-5">
                <div className="content-row-3">
                  <p className="text-wrapper-12">
                    Realize what was important today
                    <br />
                    (self-awareness)
                  </p>
                  <div className="tag-wrapper">
                    <div className="tag-8">
                      <div className="text-wrapper-13">We solve</div>
                      <img
                        className="check"
                        alt="Check"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/check.svg"
                      />
                    </div>
                  </div>
                </div>
                <img
                  className="vector-20"
                  alt="Vector"
                  src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-37.svg"
                />
                <div className="content-row-3">
                  <div className="text-wrapper-12">Read books</div>
                  <div className="tag-wrapper">
                    <div className="tag-8">
                      <div className="text-wrapper-13">We solve</div>
                      <img
                        className="check"
                        alt="Check"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/check.svg"
                      />
                    </div>
                  </div>
                </div>
                <img
                  className="vector-20"
                  alt="Vector"
                  src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-38.svg"
                />
                <div className="content-row-3">
                  <p className="text-wrapper-12">
                    Define where do you want to be successful (goals)
                  </p>
                  <div className="tag-wrapper">
                    <div className="tag-9">
                      <div className="text-wrapper-13">You solve</div>
                      <img
                        className="check"
                        alt="Check"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/check.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="txt-container-5">
              <div className="blue-blur-3" />
              <div className="frame-6">
                <div className="title-15">
                  Sign Up for
                  <br />
                  the pre-launch
                </div>
              </div>
              <div className="tag-10">
                <div className="text-wrapper-14">BETA</div>
              </div>
              <div className="form-container">
                <input
                  className="input"
                  placeholder="Email"
                  type="email"
                  value={email2}
                  onChange={handleEmailChange2}
                />
                <button
                  className={`button ${isClicked8 ? "clicked" : ""}`}
                  onClick={handleSubmit2}
                >
                  <div className="text-wrapper-2">Submit</div>
                </button>
              </div>
            </div>
            <footer className="footer">
              <div className="header-2">
                <Logo2 className="logo-2" />
                <div className="menu-items">
                  <div className="menu-item">
                    <button
                      className={`button-4 ${isClicked1 ? "clicked" : ""}`}
                      onClick={handleClick1}
                    >
                      <div className="text-wrapper-15">Privacy</div>
                    </button>
                  </div>
                  <div className="menu-item">
                    <button
                      className={`button-4 ${isClicked2 ? "clicked" : ""}`}
                      onClick={handleClick2}
                    >
                      <div className="text-wrapper-15">
                        Terms &amp; Conditions
                      </div>{" "}
                    </button>
                  </div>
                  <div className="menu-item">
                    <button
                      className={`button-4 ${isClicked3 ? "clicked" : ""}`}
                      onClick={handleClick3}
                    >
                      <div className="text-wrapper-15">Help</div>
                    </button>
                  </div>
                </div>
              </div>
              <img
                className="vector-21"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/vector-37-1.svg"
              />
              <p className="text-wrapper-16">
                2024 HTBS. A Sellsgram LTD. All Rights reserved.
              </p>
            </footer>
          </>
        )}

        {screenWidth >= 1280 && (
          <div className="overlap-9">
            <img
              className="stroke-circle-3"
              alt="Stroke circle"
              src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/stroke-circle-1.svg"
            />
            <div className="blue-blur-4" />
            <div className="overlap-wrapper">
              <div className="overlap-10">
                <div className="overlap-group-4">
                  <div className="blue-blur-5" />
                  <img
                    className="image-4"
                    alt="Image"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/image-13-1.png"
                  />
                  <div className="rectangle-12" />
                  <img
                    className="image-5"
                    alt="Image"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/image-14-1@2x.png"
                  />
                  <img
                    className="image-6"
                    alt="Image"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/image-15-1@2x.png"
                  />
                  <div className="rectangle-13" />
                  <div className="rectangle-14" />
                  <div className="rectangle-15" />
                  <div className="rectangle-16" />
                  <div className="rectangle-17" />
                  <div className="card-3">
                    <div className="date-2">
                      <div className="text-wrapper-17">27 December</div>
                    </div>
                    <div className="text-container-3">
                      <div className="copy-container-6">
                        <div className="content-row-4">
                          <div className="text-wrapper-18">1.</div>
                          <div className="text-wrapper-19">
                            I took some water
                          </div>
                          <img
                            className="tag-11"
                            alt="Tag"
                            src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/tag-8.svg"
                          />
                        </div>
                        <div className="content-row-4">
                          <div className="text-wrapper-18">2.</div>
                          <div className="text-wrapper-19">
                            I took something else...
                          </div>
                          <img
                            className="tag-11"
                            alt="Tag"
                            src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/tag-9.svg"
                          />
                        </div>
                        <img
                          className="tag-11"
                          alt="Tag"
                          src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/tag-7.svg"
                        />
                      </div>
                      <div className="text-wrapper-20">Done</div>
                    </div>
                  </div>
                  <div className="frame-7">
                    <div className="column-3">
                      <div className="text-wrapper-21">03.01.2024</div>
                      <div className="text-wrapper-22">1 книга на місяць</div>
                      <img
                        className="tag-12"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/tag-10.svg"
                      />
                    </div>
                    <div className="column-4">
                      <div className="text-wrapper-21">03.01.2024</div>
                      <p className="text-wrapper-22">
                        Вільно навчитись грати блюз на піаніно
                      </p>
                      <img
                        className="tag-12"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/tag-11.svg"
                      />
                    </div>
                    <div className="column-3">
                      <div className="text-wrapper-21">03.01.2024</div>
                      <div className="text-wrapper-22">Стенд ап</div>
                      <img
                        className="tag-12"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/tag-12.svg"
                      />
                    </div>
                    <div className="column-3">
                      <div className="text-wrapper-21">03.01.2024</div>
                      <div className="text-wrapper-22">Table 9</div>
                      <img
                        className="tag-12"
                        alt="Tag"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/tag-13.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-container-4">
                  <div className="text-wrapper-23">Profile</div>
                  <div className="copy-container-7">
                    <div className="content-row-5">
                      <img
                        className="img-2"
                        alt="Solar book outline"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/solar-book-outline-1.svg"
                      />
                      <div className="text-wrapper-24">Books</div>
                      <img
                        className="back-6"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/back-5.svg"
                      />
                    </div>
                    <img
                      className="vector-22"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-13-1.svg"
                    />
                    <div className="content-row-5">
                      <img
                        className="img-2"
                        alt="Fluent list"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/fluent-list-24-filled-1.svg"
                      />
                      <div className="text-wrapper-24">Habits</div>
                      <img
                        className="back-6"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/back-6.svg"
                      />
                    </div>
                    <img
                      className="vector-22"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-14-2.svg"
                    />
                    <div className="content-row-5">
                      <img
                        className="img-2"
                        alt="Basil calendar"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/basil-calendar-outline-1.svg"
                      />
                      <div className="text-wrapper-24">Plan</div>
                      <img
                        className="back-6"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/back-7.svg"
                      />
                    </div>
                    <img
                      className="vector-22"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-15-1.svg"
                    />
                    <div className="content-row-5">
                      <img
                        className="img-2"
                        alt="Solar archive"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/solar-archive-outline-1.svg"
                      />
                      <div className="text-wrapper-24">Archive</div>
                      <img
                        className="back-6"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/back-8.svg"
                      />
                    </div>
                    <img
                      className="vector-22"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-14-3.svg"
                    />
                    <div className="content-row-5">
                      <img
                        className="img-2"
                        alt="Solar document"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/solar-document-outline-1.svg"
                      />
                      <div className="text-wrapper-24">
                        Terms &amp; Policies
                      </div>
                      <img
                        className="back-6"
                        alt="Back"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/back-9.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="why-it-works" className="cards-3">
              <div className="card-4">
                <div className="ant-design-smile-3">
                  <div className="overlap-group-5">
                    <img
                      className="vector-23"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-22-2.svg"
                    />
                    <img
                      className="vector-24"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-24-2.svg"
                    />
                    <img
                      className="vector-25"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-25-2.svg"
                    />
                    <img
                      className="vector-26"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-23-2.svg"
                    />
                    <img
                      className="vector-27"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-2.svg"
                    />
                    <div className="ellipse-3" />
                    <img
                      className="vector-28"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-21-2.svg"
                    />
                  </div>
                </div>
                <div className="copy-container">
                  <div className="title-16">Self-awareness</div>
                  <p className="title-17">
                    Realize what was important today and why was it important to
                    you
                  </p>
                </div>
                <img
                  className="group-4"
                  alt="Group"
                  src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/group-7-1@2x.png"
                />
              </div>
              <div className="card-5">
                <div className="copy-container-2">
                  {/* <div className="title-33">How to become sucessful at smth?</div> */}
                  <div className="title-3">Instrument</div>
                  <div className="frame">
                    <div className="frame-2">
                      <img
                        className="vector-stroke"
                        alt="Vector stroke"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-5--stroke--4.svg"
                      />
                      <div className="title-18">Books (knowledge)</div>
                    </div>
                    <div className="frame-2">
                      <img
                        className="vector-stroke"
                        alt="Vector stroke"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-5--stroke--5.svg"
                      />
                      <div className="title-18">Gym (muscles)</div>
                    </div>
                    <div className="frame-2">
                      <img
                        className="vector-stroke"
                        alt="Vector stroke"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-5--stroke--4.svg"
                      />
                      <div className="title-18">Cookery School (food)</div>
                    </div>
                    <div className="frame-2">
                      <img
                        className="vector-stroke"
                        alt="Vector stroke"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-5--stroke--4.svg"
                      />
                      <div className="title-18">Piano lessons (music)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-4">
                <div className="ant-design-smile-4">
                  <div className="overlap-group-6">
                    <img
                      className="vector-29"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-22-3.svg"
                    />
                    <img
                      className="vector-30"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-24-3.svg"
                    />
                    <img
                      className="vector-31"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-25-3.svg"
                    />
                    <img
                      className="vector-32"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-23-3.svg"
                    />
                    <img
                      className="vector-33"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-3.svg"
                    />
                    <div className="ellipse-4" />
                    <img
                      className="vector-34"
                      alt="Vector"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-21-3.svg"
                    />
                  </div>
                  <img
                    className="title-19"
                    alt="Title"
                    src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/title-1.svg"
                  />
                </div>
                <div className="copy-container">
                  <div className="title-20">Goals</div>
                  <p className="title-17">
                    Define where do you
                    <br />
                    want to be successful
                    <br />
                    (what do you want to achieve)
                  </p>
                </div>
              </div>
              <img
                className="vector-35"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-32.svg"
              />
              <img
                className="vector-36"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-33.svg"
              />
              <img
                className="arrow"
                alt="Arrow"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/arrow@2x.png"
              />
              <img
                className="arrow-2"
                alt="Arrow"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/arrow-1@2x.png"
              />
            </div>
            <div className="blue-blur-6" />
            <div className="stroke-circle-4" />
            <div className="header-3">
              <div className="menu-items-2">
                <div className="menu-item-2">
                  <button
                    className={`button-4`}
                    onClick={scrollToNextSectionWhy}
                  >
                    <div className="text-wrapper-15">Why?</div>
                  </button>
                </div>
                <div className="menu-item-2">
                  <button
                    className={`button-4`}
                    onClick={scrollToNextSectionShit}
                  >
                    <div className="text-wrapper-15">F*ck shit</div>
                  </button>
                </div>
                <div className="menu-item-2">
                  <button
                    className={`button-4`}
                    onClick={scrollToNextSectionHow}
                  >
                    <div className="text-wrapper-15">How it works?</div>
                  </button>
                </div>
              </div>
              <button
                className={`button-3 ${isClicked11 ? "clicked" : ""}`}
                onClick={handleClick11}
              >
                <div className="text-wrapper-2">Subscribe</div>
              </button>

              <Logo2 className="logo-4" />
            </div>
            <footer className="footer-2">
              <div className="header-4">
                <Logo2 className="logo-2" />
                <div className="menu-items-3">
                  <div className="menu-item-2">
                    <button
                      className={`button-4 ${isClicked4 ? "clicked" : ""}`}
                      onClick={handleClick4}
                    >
                      <div className="text-wrapper-15">Privacy</div>
                    </button>
                  </div>
                  <div className="menu-item-2">
                    <button
                      className={`button-4 ${isClicked5 ? "clicked" : ""}`}
                      onClick={handleClick5}
                    >
                      <div className="text-wrapper-15">
                        Terms &amp; Conditions
                      </div>
                    </button>
                  </div>
                  <div className="menu-item-2">
                    <button
                      className={`button-4 ${isClicked6 ? "clicked" : ""}`}
                      onClick={handleClick6}
                    >
                      <div className="text-wrapper-15">Help</div>
                    </button>
                  </div>
                </div>
              </div>
              <img
                className="vector-21"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-37-2.svg"
              />
              <p className="text-wrapper-25">
                2024 HTBS. A Sellsgram LTD. All Rights reserved.
              </p>
            </footer>
            <div className="txt-container-6">
              <p className="title-21">
                <span className="text-wrapper">Get a free app </span>
                <div className="tag-130">
                  <div className="text-wrapper-14">BETA</div>
                </div>
              </p>
              <img
                className="vector-377"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-19-2.svg"
              />
              <p className="title-21">
                <span className="span-2">to achieve</span>
                <span className="text-wrapper"> goals </span>
              </p>
              <div className="form-container-2">
                <input
                  className="input-2"
                  placeholder="Email"
                  type="email"
                  value={email3}
                  onChange={handleEmailChange3}
                />
                <button
                  className={`button-3 ${isClicked9 ? "clicked" : ""}`}
                  onClick={handleSubmit3}
                >
                  <div className="text-wrapper-2">Submit</div>
                </button>
              </div>
            </div>
            <div className="txt-container-7">
              <p className="title-22">
                <span className="text-wrapper">Why </span>
                <span className="text-wrapper-3">HTBS?</span>
                <span className="text-wrapper"> Because</span>
              </p>
              <p className="title-17">Real success comes from nothing</p>
            </div>
            <div className="txt-container-8">
              <img
                className="vector-38"
                alt="Vector"
                src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-19-3.svg"
              />
              <p id="stop-believing-in-this-shit" className="title-23">
                Stop believing in this sh*t 💩
              </p>
              <div className="cards-4">
                <div className="frame-4">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <div className="title-9">
                        Buy
                        <br />a course
                      </div>
                      <img
                        className="game-icons-female"
                        alt="Game icons female"
                        src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/game-icons-female-legs-1.svg"
                      />
                      <div className="rectangle-8" />
                    </div>
                  </div>
                  <p className="title-10">Do you want to be successful</p>
                </div>
                <div className="frame-4">
                  <div className="div-wrapper">
                    <div className="overlap-group-3">
                      <div className="overlap-5">
                        <div className="overlap-6">
                          <div className="title-11">7</div>
                          <div className="rectangle-9" />
                          <div className="rectangle-10" />
                        </div>
                        <div className="overlap-7">
                          <div className="title-12">7</div>
                          <div className="rectangle-11" />
                        </div>
                      </div>
                      <div className="overlap-8">
                        <div className="title-12">7</div>
                        <div className="rectangle-11" />
                      </div>
                    </div>
                  </div>
                  <div className="title-wrapper">
                    <p className="title-13">
                      You won 100K,
                      <br />
                      take you win!
                    </p>
                  </div>
                </div>
                <div className="frame-4">
                  <div className="overlap-group-wrapper">
                    <div className="overlap-group-3">
                      <div className="title-14">
                        $1M in
                        <br />a month
                      </div>
                      <div className="rectangle-8" />
                    </div>
                  </div>
                  <p className="title-10">
                    How I get
                    <br />
                    $1M in a month?
                  </p>
                </div>
              </div>
            </div>
            <img
              className="vector-39"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-17-1.svg"
            />
            <img
              className="vector-40"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/vector-18-1.svg"
            />
            <div id="how-it-works" className="frame-8">
              <div className="txt-container-9">
                <div className="title-23">Real approach</div>
                <p className="title-17">
                  One of many, this one has worked for me for the past 3 years.
                </p>
              </div>
              <div className="copy-container-8">
                <div className="content-row-6">
                  <p className="text-wrapper-26">
                    Realize what was important today
                    <br />
                    (self-awareness)
                  </p>
                  <div className="tag-8">
                    <div className="text-wrapper-13">We solve</div>
                    <img
                      className="check"
                      alt="Check"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/check-3.svg"
                    />
                  </div>
                </div>
                <div className="content-row-6">
                  <div className="text-wrapper-27">Read books</div>
                  <div className="tag-8">
                    <div className="text-wrapper-13">We solve</div>
                    <img
                      className="check"
                      alt="Check"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a520be4b2d892ae8a7cc/img/check.svg"
                    />
                  </div>
                </div>
                <div className="content-row-6">
                  <p className="text-wrapper-26">
                    Define where do you want to be successful (goals)
                  </p>
                  <div className="tag-9">
                    <div className="text-wrapper-13">You solve</div>
                    <img
                      className="check"
                      alt="Check"
                      src="https://cdn.animaapp.com/projects/65a2c4a47481603698ba753b/releases/65a3a757dd6f27faf0069486/img/check-3.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="txt-container-10">
              <div className="blue-blur-7" />
              <div className="frame-6">
                <div className="title-24">
                  Sign Up for
                  <br />
                  the pre-launch
                </div>
                <div className="tag-13">
                  <div className="text-wrapper-14">BETA</div>
                </div>
              </div>
              <div className="form-container-2">
                <input
                  className="input-2"
                  placeholder="Email"
                  type="email"
                  value={email4}
                  onChange={handleEmailChange4}
                />
                <button
                  className={`button-3 ${isClicked10 ? "clicked" : ""}`}
                  onClick={handleSubmit4}
                >
                  <div className="text-wrapper-2">Submit</div>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
